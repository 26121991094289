import { inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { CredentialsService } from '@shared/services/credentials.service';
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { ConfigurationService } from '@ep/shared';

/**
 * Class representing a SignalR service.
 * @class
 * @public
 * @@Injectable({
 *   providedIn: 'root',
 * })
 */
@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private readonly credentialsService = inject(CredentialsService);
  private readonly configurationService = inject(ConfigurationService);

  hubConnection!: signalR.HubConnection;
  private _connectionStarted = false;

  /**
   * Returns whether the connection has started or not.
   *
   * @returns {boolean} True if the connection has started, false otherwise.
   */
  public hasConnectionStarted() {
    return this._connectionStarted;
  }

  /**
   * Starts a connection to the signalR service hub.
   *
   * @return {void}
   */
  public startConnection() {
    const options: IHttpConnectionOptions = {
      accessTokenFactory: () => {
        return this.credentialsService.credentials?.jwt ?? '';
      },
    };

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.configurationService.serviceApiBaseUrl}/service-hub`, options)
      .withAutomaticReconnect()
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this._connectionStarted = true;
        console.log('Connection started!');
      })
      .catch((err) => {
        this._connectionStarted = false;
        console.error('Error while starting connection: ' + err);
      });
  }

  /**
   * Disconnects from the hub connection.
   *
   * @returns {Promise} A promise that resolves when the disconnection is complete or rejects with an error.
   */
  public disconnect() {
    this._connectionStarted = false;
    this.hubConnection
      .stop()
      .then(() => {
        console.log('Disconnected.');
      })
      .catch((err) => {
        console.error('Error while disconnecting: ' + err);
      });
  }
}
