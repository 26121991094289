import { Injectable } from '@angular/core';
import { BaseApiService } from '@ep/shared';
import { catchError, of } from 'rxjs';
import { DeviceTypes } from '@shared/enums';

export interface DeviceTracking {
  DeviceId: number;
  ApplicationVersion: string;
  Make: string;
  Model: string;
  SerialNumber: string;
  CreatedOn: Date;
}

export interface Device {
  DeviceId: number;
  Name: string;
  DeviceType: number;
  StatusType: number;
  DeviceTrackings: DeviceTracking[];
}

export interface CreateDeviceRequest {
  Name: string;
  AuthorizationCode: string;
}

export interface UpdateDeviceRequest {
  DeviceId: number;
  Name?: string;
  AuthorizationCode?: string;
}

export interface LegacyDeviceManageRequest {
  DeviceId?: number;
  Name: string;
  DisplayCode: string;
  LocationId?: number;
  MerchantId?: number;
  DeviceType?: DeviceTypes;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceService extends BaseApiService {
  private readonly deviceEndpoint = '/v2/Devices';
  private readonly deviceLegacyEndpoint = '/Device';

  findAll(merchantId: number) {
    return this.get(`${this.deviceEndpoint}`).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  findUnique(deviceId: number) {
    return this.get(`${this.deviceEndpoint}/${deviceId}`).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  create(newDevice: LegacyDeviceManageRequest) {
    return this.post(
      `${this.deviceLegacyEndpoint}/Save`,
      {
        ...newDevice,
      },
      true
    );
  }

  update(updatedDevice: LegacyDeviceManageRequest) {
    // This shouldn't be a POST, but legacy is dumb and uses a POST for an update
    return this.post(
      `${this.deviceLegacyEndpoint}/Save`,
      {
        ...updatedDevice,
      },
      true
    );
  }
}
