import {
  faArrowLeft,
  faBars,
  faCalendarDays,
  faClock,
  faComment,
  faCopy,
  faCreditCard,
  faDollarSign,
  faFileExport,
  faGear,
  faHouse,
  faMedal,
  faMobileScreenButton,
  faMoneyBill,
  faPenToSquare,
  faPercent,
  faPersonCircleQuestion,
  faSackDollar,
  faStar,
  faTriangleExclamation,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/angular-fontawesome';

export enum FA_ICON {
  BARS = 'faBars',
  HOUSE = 'faHouse',
  MONEY_BILL = 'faMoneyBill',
  USERS = 'faUsers',
  CREDIT_CARD = 'faCreditCard',
  STAR = 'faStar',
  COMMENT = 'faComment',
  FILE_EXPORT = 'faFileExport',
  GEAR = 'faGear',
  MOBILE_SCREEN_BUTTON = 'faMobileScreenButton',
  PERSON_CIRCLE_QUESTION = 'faPersonCircleQuestion',
  CLOCK = 'faClock',
  CALENDAR_DAYS = 'faCalendarDays',
  SACK_DOLLAR = 'faSackDollar',
  MEDAL = 'faMedal',
  PERCENT = 'faPercent',
  DOLLAR_SIGN = 'faDollarSign',
  COPY = 'faCopy',
  PEN_TO_SQUARE = 'faPenToSquare',
  ARROW_LEFT = 'faArrowLeft',
  TRIANGLE_EXCLAMATION = 'faTriangleExclamation',
}

export const FONT_AWESOME_ICONS: { [index: string]: IconDefinition } = {
  [FA_ICON.BARS]: faBars,
  [FA_ICON.HOUSE]: faHouse,
  [FA_ICON.MONEY_BILL]: faMoneyBill,
  [FA_ICON.USERS]: faUsers,
  [FA_ICON.CREDIT_CARD]: faCreditCard,
  [FA_ICON.STAR]: faStar,
  [FA_ICON.COMMENT]: faComment,
  [FA_ICON.FILE_EXPORT]: faFileExport,
  [FA_ICON.GEAR]: faGear,
  [FA_ICON.MOBILE_SCREEN_BUTTON]: faMobileScreenButton,
  [FA_ICON.PERSON_CIRCLE_QUESTION]: faPersonCircleQuestion,
  [FA_ICON.CLOCK]: faClock,
  [FA_ICON.CALENDAR_DAYS]: faCalendarDays,
  [FA_ICON.SACK_DOLLAR]: faSackDollar,
  [FA_ICON.MEDAL]: faMedal,
  [FA_ICON.PERCENT]: faPercent,
  [FA_ICON.DOLLAR_SIGN]: faDollarSign,
  [FA_ICON.COPY]: faCopy,
  [FA_ICON.PEN_TO_SQUARE]: faPenToSquare,
  [FA_ICON.ARROW_LEFT]: faArrowLeft,
  [FA_ICON.TRIANGLE_EXCLAMATION]: faTriangleExclamation,
};
