import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorContactCustomerSupportComponent } from './pages/error-contact-customer-support/error-contact-customer-support.component';

@NgModule({
  imports: [TranslateModule, CommonModule],
  exports: [],
  declarations: [],
})
export class SharedModule {}
