import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { Shell } from '@app/shell/shell.service';
import { AccessGuard } from '@shared/guards/access.guard';
import { AccessType } from '@shared/enums';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: ':locationId/admin',
      component: AdminComponent,
      data: { title: 'Enroll And Pay' },
    },
    {
      path: ':locationId/admin/devices',
      loadChildren: () => import('./submodules/devices/devices.module').then((m) => m.DevicesModule),
      canActivate: [AccessGuard],
      data: { requiredAccessTypes: [AccessType.DeviceAdmin] },
    },
    {
      path: ':locationId/admin/locations',
      loadChildren: () => import('./submodules/locations/locations.module').then((m) => m.LocationsModule),
      canActivate: [AccessGuard],
      data: { requiredAccessTypes: [AccessType.LocationAdmin] },
    },
    {
      path: ':locationId/admin/employees',
      loadChildren: () => import('./submodules/employees/employees.module').then((m) => m.EmployeesModule),
      canActivate: [AccessGuard],
      data: { requiredAccessTypes: [AccessType.EmployeeAdmin] },
    },
    {
      path: ':locationId/admin/loyalty-program',
      loadChildren: () =>
        import('./submodules/loyalty-program/loyalty-program.module').then((m) => m.LoyaltyProgramModule),
      canActivate: [AccessGuard],
      data: { requiredAccessTypes: [AccessType.LoyaltyProgramAdmin] },
    },
    {
      path: ':locationId/admin/questions',
      loadChildren: () => import('./submodules/questions/questions.module').then((m) => m.QuestionsModule),
      canActivate: [AccessGuard],
      data: { requiredAccessTypes: [AccessType.QuestionAdmin] },
    },
    {
      path: ':locationId/admin/billing',
      loadChildren: () => import('./submodules/billing/billing.module').then((m) => m.BillingModule),
      canActivate: [AccessGuard],
      data: { requiredAccessTypes: [AccessType.BillingAdmin] },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
