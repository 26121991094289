import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApplicationProfile } from '../models/application-profile.model';
import { isNotNullOrUndefined } from '@shared/utils';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApplicationProfileService {
  private applicationProfileSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  applicationProfile$: Observable<ApplicationProfile> = this.applicationProfileSubject
    .asObservable()
    .pipe(filter(isNotNullOrUndefined));

  setApplicationProfile(applicationProfile: ApplicationProfile): void {
    this.applicationProfileSubject.next(applicationProfile);
  }

  getApplicationProfile(): any {
    return this.applicationProfileSubject.value;
  }
}
