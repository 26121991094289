import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@shared';
import { ResponseMessagesTypes } from '@app/@shared/models/constants';
import { LoginResponse } from '@app/@shared/models/login-response.model';
import { LoginRequest } from '@app/@shared/models/login-request.model';
import { ApplicationProfile } from '@app/@shared/models/application-profile.model';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { Credentials } from '@app/@shared/models/credentials.model';
import { MessageService } from 'primeng/api';
import { AuthenticationService, CredentialsService } from '..';
import { SubdomainTypes } from '@app/@shared/enums/subdomain-types.enum';
import { SubdomainService } from '@app/@shared/services/subdomain.service';
import { LoginStatusTypes } from '@app/@shared/enums/login-status-types.enum';
import { Observable } from 'rxjs';
import { LoginTypes } from '@shared/enums';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  fgLogin!: FormGroup;
  fgTermsAndConditionsForm!: FormGroup;
  isLoading = false;
  subdomainType!: SubdomainTypes | null;
  SubdomainTypes = SubdomainTypes;
  shouldShowTermsAndConditions: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private applicationProfileService: ApplicationProfileService,
    private messageService: MessageService,
    private subdomainService: SubdomainService
  ) {}

  ngOnInit() {
    this.subdomainType = this.subdomainService.getDomain();
    this.createLoginForm();
    this.createTermsAndConditionsForm();
  }

  onLoginClicked(): void {
    this.executeLoginLogic(this.authenticationService.login.bind(this.authenticationService));
  }

  onLoginWithTcClicked(): void {
    this.executeLoginLogic(this.authenticationService.loginWithTc.bind(this.authenticationService));
  }

  onForgotPasswordClicked(): void {
    this.router.navigate(['auth/reset-password']);
  }

  private executeLoginLogic(loginMethod: (req: LoginRequest) => Observable<LoginResponse>): void {
    this.fgLogin.markAllAsTouched();

    if (!this.fgLogin.valid) return;

    this.isLoading = true;

    const loginRequest: LoginRequest = {
      ...this.fgLogin.value,
      clientAccessIds: [this.credentialsService.credentials?.clientAccessId || ''],
      referenceIdent: '',
      loginType: LoginTypes.Employee,
    };

    loginMethod(loginRequest)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this.fgLogin.markAsPristine();
        })
      )
      .subscribe(this.handleLoginResponse, (error) => {
        this.showErrorMessage(error);
      });
  }

  private handleLoginResponse = (loginResponse: LoginResponse) => {
    if (this.hasInvalidUsernamePasswordError(loginResponse)) {
      this.showErrorMessage('Invalid email address or password.');
      return;
    }

    if (loginResponse.LoginStatus === LoginStatusTypes.TCFlag) {
      this.shouldShowTermsAndConditions = true;
      return;
    }

    this.saveCredentials(loginResponse);
    this.redirectToDashboard();
  };

  private hasInvalidUsernamePasswordError(loginResponse: LoginResponse): boolean {
    return (
      loginResponse.ResponseMessages &&
      loginResponse.ResponseMessages.includes(ResponseMessagesTypes.InvalidUsernamePassword)
    );
  }

  private showErrorMessage(message: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message,
    });
  }

  private saveCredentials(loginResponse: LoginResponse): void {
    const credentials: Credentials = {
      clientAccessId: loginResponse.ClientAccessId,
      jwt: loginResponse.JWT,
    };
    this.credentialsService.setCredentials(credentials);
  }

  private redirectToDashboard(): void {
    this.authenticationService
      .getApplicationProfile()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (applicationProfile: ApplicationProfile) => {
          this.applicationProfileService.setApplicationProfile(applicationProfile);
          this.router.navigate([applicationProfile.Location.LocationId, 'dashboard']);
        },
      });
  }

  private createLoginForm() {
    this.fgLogin = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  private createTermsAndConditionsForm() {
    this.fgTermsAndConditionsForm = this.formBuilder.group({
      termsAndConditions: [false, Validators.pattern('true')],
      privacyPolicy: [false, Validators.pattern('true')],
      merchantFunded: [false, Validators.pattern('true')],
      monthlySubscription: [false, Validators.pattern('true')],
    });
  }
}
