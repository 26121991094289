import { Component, OnInit } from '@angular/core';
import { ApplicationProfile } from '@app/@shared/models/application-profile.model';
import { Credentials } from '@app/@shared/models/credentials.model';
import { AuthenticationService, CredentialsService } from '..';
import { ApplicationProfileService } from '@app/@shared/services/application-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubdomainService } from '@app/@shared/services/subdomain.service';
import { SubdomainTypes } from '@app/@shared/enums/subdomain-types.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';

@UntilDestroy()
@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss'],
})
export class ImpersonateComponent implements OnInit {
  subdomainType!: SubdomainTypes | null;
  SubdomainTypes = SubdomainTypes;
  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private applicationProfileService: ApplicationProfileService,
    private subdomainService: SubdomainService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.pipe(untilDestroyed(this)).subscribe((params) => {
      const jwt = params['jwt'];
      if (jwt) {
        this.setJwtAndNavigate(jwt);
        this.isLoading = false;
        return;
      }
    });

    this.subdomainType = this.subdomainService.getDomain();
  }

  private redirectToDashboard(): void {
    this.authenticationService
      .getApplicationProfile()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (applicationProfile: ApplicationProfile) => {
          this.applicationProfileService.setApplicationProfile(applicationProfile);
          this.router.navigate([applicationProfile.Location.LocationId, 'dashboard']);
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Error impersonating!',
          });
          this.router.navigate(['/auth/login']);
        },
      });
  }

  private setJwtAndNavigate(jwt: string) {
    const credentials: Credentials = {
      clientAccessId: '',
      jwt: jwt,
    };
    this.credentialsService.setCredentials(credentials);
    this.redirectToDashboard();
  }
}
