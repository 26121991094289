<ep-auth-container
  [imageSrc]="
    subdomainType === SubdomainTypes.EagleProcessing
      ? '../../../assets/eagle-logo.png'
      : '../../../assets/enroll-and-pay-logo.png'
  "
  [class.terms-and-conditions-container]="shouldShowTermsAndConditions"
>
  <div class="mb-4">
    <div *ngIf="!shouldShowTermsAndConditions">
      <h5 class="fw-semibold">Login with your merchant account</h5>
    </div>
    <div *ngIf="shouldShowTermsAndConditions">
      <h5 class="fw-semibold">Please accept our terms and conditions</h5>
    </div>
  </div>

  <form *ngIf="!shouldShowTermsAndConditions" [formGroup]="fgLogin" (keyup.enter)="onLoginClicked()">
    <div class="form-group mb-4">
      <div class="flex flex-column gap-2">
        <label class="fw-semibold" htmlFor="username">Email:</label>
        <input
          type="email"
          pInputText
          formControlName="username"
          autocomplete="email"
          [class.is-invalid]="
            fgLogin.get('username')?.invalid && (fgLogin.get('username')?.dirty || fgLogin.get('username')?.touched)
          "
          data-cy="merchant-login-email"
        />
        <div class="invalid-feedback" *ngIf="fgLogin.get('username')?.errors?.['required']">Email is required</div>
        <div class="invalid-feedback" *ngIf="fgLogin.get('username')?.errors?.['email']">Email is invalid</div>
      </div>
    </div>
    <div class="form-group mb-4">
      <div class="flex flex-column gap-2">
        <label class="fw-semibold" htmlFor="password">Password:</label>
        <p-password
          formControlName="password"
          [toggleMask]="true"
          [feedback]="false"
          autocomplete="current-password"
          [class.is-invalid]="
            fgLogin.get('password')?.invalid && (fgLogin.get('password')?.dirty || fgLogin.get('password')?.touched)
          "
          data-cy="merchant-login-password"
        ></p-password>
        <div class="invalid-feedback" *ngIf="fgLogin.get('password')?.errors?.['required']">Password is required</div>
      </div>
    </div>
    <div class="mb-4">
      <p-button
        label="LOGIN"
        [loading]="isLoading"
        (onClick)="onLoginClicked()"
        data-cy="merchant-login-button"
      ></p-button>
    </div>
    <div>
      Forgot Password?
      <a (click)="onForgotPasswordClicked()" routerLinkActive="active">Click here to reset</a>
    </div>
  </form>

  <form
    *ngIf="shouldShowTermsAndConditions"
    [formGroup]="fgTermsAndConditionsForm"
    (keyup.enter)="onLoginWithTcClicked()"
  >
    <div class="form-group mb-4">
      <div class="flex flex-column gap-4">
        <div>
          <p-checkbox formControlName="termsAndConditions" [binary]="true"></p-checkbox>
          <label class="fw-semibold checkbox-label" for="privacyPolicy">
            I ACCEPT THE
            <a
              href="../../../assets/documents/TermsAndConditions-03-10-23.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              TERMS AND CONDITIONS
            </a>
          </label>
        </div>
        <div>
          <p-checkbox formControlName="privacyPolicy" [binary]="true"></p-checkbox>
          <label class="fw-semibold checkbox-label" for="privacyPolicy">
            I ACCEPT THE
            <a href="../../../assets/documents/PrivacyPolicy-03-10-23.pdf" target="_blank" rel="noopener noreferrer">
              PRIVACY POLICY
            </a>
          </label>
        </div>
        <div>
          <p-checkbox formControlName="merchantFunded" [binary]="true"></p-checkbox>
          <label class="fw-semibold checkbox-label" for="merchantFunded"
            >I UNDERSTAND THAT ALL REWARDS ARE MERCHANT FUNDED</label
          >
        </div>
        <div>
          <p-checkbox formControlName="monthlySubscription" [binary]="true"></p-checkbox>
          <label class="fw-semibold checkbox-label" for="monthlySubscription"
            >AFTER THE FREE TRIAL PERIOD, I UNDERSTAND THE SERVICE WILL REQUIRE A MONTHLY SUBSCRIPTION</label
          >
        </div>
      </div>
    </div>

    <div class="mb-4">
      <p-button
        label="I ACCEPT THE TERMS AND CONDITIONS"
        [loading]="isLoading"
        (onClick)="onLoginWithTcClicked()"
        [disabled]="
          !fgTermsAndConditionsForm.get('termsAndConditions')?.value ||
          !fgTermsAndConditionsForm.get('privacyPolicy')?.value ||
          !fgTermsAndConditionsForm.get('merchantFunded')?.value ||
          !fgTermsAndConditionsForm.get('monthlySubscription')?.value
        "
      >
      </p-button>
    </div>
  </form>
</ep-auth-container>
