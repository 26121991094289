import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { BaseApiService } from '@ep/shared';
import { DailySaleOverviewResponse } from '@shared/models/daily-sale-overview.model';

export interface GetDailyDashboardDataRequest {
  FromDate: string;
  ToDate: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoyaltyProgramService extends BaseApiService {
  private readonly loyaltyProgramEndpoint = `/v2/LoyaltyPrograms`;
  private readonly dailyEndpoint = `${this.loyaltyProgramEndpoint}/daily`;

  findDaily(request: GetDailyDashboardDataRequest): Observable<DailySaleOverviewResponse | null> {
    return this.get<DailySaleOverviewResponse>(`${this.dailyEndpoint}`, {
      fromDate: request.FromDate,
      toDate: request.ToDate,
    }).pipe(
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }
}
