/**
 * Enum defining the various SignalR target events.
 *
 * @enum {string}
 * @readonly
 */
export enum SignalrTargetsEnum {
  UpdateDashboard = 'onDashboardUpdate',
  UpdateApplicationProfile = 'onApplicationProfileUpdate',
  CreateLocation = 'onLocationCreate',
  UpdateLocation = 'onLocationUpdate',
  DeleteLocation = 'onLocationDelete',
  CreateEmployee = 'onEmployeeCreate',
  UpdateEmployee = 'onEmployeeUpdate',
  DeleteEmployee = 'onEmployeeDelete',
  CreateConsumer = 'onConsumerCreate',
  UpdateConsumer = 'onConsumerUpdate',
  DeleteConsumer = 'onConsumerDelete',
  CreateOffer = 'onOfferCreate',
  UpdateOffer = 'onOfferUpdate',
  DeleteOffer = 'onOfferDelete',
  CreateQuestion = 'onQuestionCreate',
  UpdateQuestion = 'onQuestionUpdate',
  DeleteQuestion = 'onQuestionDelete',
  CreateLoyaltyProgram = 'onLoyaltyProgramCreate',
  UpdateLoyaltyProgram = 'onLoyaltyProgramUpdate',
  DeleteLoyaltyProgram = 'onLoyaltyProgramDelete',
  CreateOrder = 'onOrderCreate',
  UpdateOrder = 'onOrderUpdate',
  DeleteOrder = 'onOrderDelete',
}
