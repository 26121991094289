<div class="header-wrapper">
  <fa-icon *ngIf="!isMobileScreen" [icon]="faBars" class="header-icon" (click)="onCloseClicked($event)"></fa-icon>
  <div>
    <button
      #btn
      type="button"
      pButton
      icon="pi pi-angle-down"
      [label]="fullUserName"
      (click)="menu.toggle($event)"
      data-cy="merchant-header-dropdown"
    ></button>
    <p-tieredMenu #menu [model]="items" [popup]="true"></p-tieredMenu>
  </div>
</div>
