import { AccessType } from '../enums/access-types.enum';
import { Resources } from '../models/constants';
import { Credentials } from '../models/credentials.model';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private _credentials: Credentials | null = null;
  private accessTypes: AccessType[] = [];

  constructor() {
    const savedCredentials =
      sessionStorage.getItem(Resources.CredentialsKey) || localStorage.getItem(Resources.CredentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      if (this._credentials) {
        this.setAccessTypesFromJwt(this._credentials.jwt);
      }
    }
  }

  get credentials(): Credentials | null {
    return this._credentials;
  }

  isAuthenticated(): boolean {
    return !!this.credentials?.jwt && !this.isTokenExpired(this.credentials.jwt);
  }

  hasAccessAny(accessTypes: AccessType[]): boolean {
    return this.accessTypes.some((accessType) => accessTypes.includes(accessType));
  }

  setCredentials(credentials?: Credentials): void {
    this._credentials = credentials || null;

    if (credentials && credentials.jwt) {
      localStorage.setItem(Resources.CredentialsKey, JSON.stringify(credentials));
      this.setAccessTypesFromJwt(credentials.jwt);
    }
  }

  private isTokenExpired(token: string): boolean {
    const decoded: any = this.getDecodedAccessToken(token);
    if (!decoded.exp) {
      return true; // If there's no expiry information, assume it's expired.
    }

    const expiryDateInMs = decoded.exp * 1000;
    const currentDateInMs = Date.now();

    return currentDateInMs >= expiryDateInMs;
  }

  private setAccessTypesFromJwt(jwt: string | null): void {
    const tokenInfo = this.getDecodedAccessToken(jwt);
    if (!tokenInfo) {
      return;
    }

    const accessTypes: AccessType[] = tokenInfo.AccessType.split(',')
      .map((accessType: string) => accessType.trim())
      .map((accessType: string) => this.mapToAccessType(accessType));

    this.setAccessTypes(accessTypes);
  }

  private setAccessTypes(accessTypes: AccessType[]): void {
    this.accessTypes = accessTypes;
  }

  private getDecodedAccessToken(token: string | null): any {
    try {
      if (!token) {
        return null;
      }
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  private mapToAccessType(accessType: string): AccessType {
    switch (accessType) {
      case 'DeviceAdmin':
        return AccessType.DeviceAdmin;
      case 'EmployeeAdmin':
        return AccessType.EmployeeAdmin;
      case 'LocationAdmin':
        return AccessType.LocationAdmin;
      case 'OfferAdmin':
        return AccessType.OfferAdmin;
      case 'ConsumerAccess':
        return AccessType.ConsumerAccess;
      case 'ConsumerReward':
        return AccessType.ConsumerReward;
      case 'SaleOverview':
        return AccessType.SaleOverview;
      case 'OrderAdmin':
        return AccessType.OrderAdmin;
      case 'ProductAdmin':
        return AccessType.ProductAdmin;
      case 'LoyaltyProgramAdmin':
        return AccessType.LoyaltyProgramAdmin;
      case 'QuestionAdmin':
        return AccessType.QuestionAdmin;
      case 'BillingAdmin':
        return AccessType.BillingAdmin;
      case 'SendReceiveMessages':
        return AccessType.SendReceiveMessages;
      case 'TabletopAdmin':
        return AccessType.TabletopAdmin;
      case 'ReportAdmin':
        return AccessType.ReportAdmin;
      case 'PayAtTableFull':
        return AccessType.PayAtTableFull;
      case 'PayAtTablePartial':
        return AccessType.PayAtTablePartial;
      default:
        throw new Error(`Unrecognized access type: ${accessType}`);
    }
  }
}
